// My Register Component

import Component from '../lib/Component';
import Elements from '../lib/Elements';
import Auth from '../lib/Authentication';

class RegisterComponent extends Component {
  constructor() {
    super({
      name: 'register',
      routerPath: '/register',
    });
  }

  render() {
    // clear the component container
    this.clearComponentContainer();

    // blue background
    document.body.className = 'bg-blue';

    // create an image
    const banner = Elements.createImage({
      src: require('../img/banner.jpg'),
      alt: 'banner',
      className: 'header__banner',
    });

    // create a logo
    const logo = Elements.createImage({
      src: require('../img/logo.png'),
      alt: 'Samen Uit, Samen Thuis',
      className: 'header__logo',
    });

    // create a container
    this.componentContainer.appendChild(
      Elements.createContainer({
        className: 'header header--register',
        children: [banner, logo],
      }),
    );

    // create a header
    const formHeader = Elements.createHeader({
      size: '2',
      className: 'authentication__header',
      textContent: 'Sign Up',
    });

    // error container
    const errorContainer = Elements.createParagraph({
      className: 'errorContainer',
    });

    // create an input
    const inputEmail = Elements.createInput({
      className: 'darkInput',
      type: 'email',
      name: 'email',
      id: 'email',
      placeholder: 'Email',
    });

    // create an input
    const inputPassword = Elements.createInput({
      className: 'darkInput',
      type: 'password',
      name: 'password',
      id: 'password',
      placeholder: 'Password',
    });

    // create a button (sign in)
    const signUpButton = Elements.createButton({
      className: 'primary',
      textContent: 'Sign Up',
      onClick: () => {
        Auth.register();
      },
    });

    // create a signin link
    const signUp = Elements.createLink({
      href: '/',
      className: 'authentication__sign',
      innerHTML: 'Already have an account? <span class="sign">Sign in</span>',
    });

    // create a container
    const authentication = Elements.createForm({
      className: 'authentication',
      children: [
        formHeader, errorContainer, inputEmail, inputPassword, signUpButton, signUp],
    });

    // create a form
    this.componentContainer.appendChild(
      Elements.createContainer({
        className: 'authenticationContainer',
        children: [authentication],
      }),
    );

    // return the register container
    return this.componentContainer;
  }
}

export default RegisterComponent;
