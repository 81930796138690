// My Meldet Component

import Component from '../lib/Component';
import Elements from '../lib/Elements';
import Router from '../router';

class MeldetComponent extends Component {
  constructor() {
    super({
      name: 'meldet',
      routerPath: '/meldet',
    });
  }

  render() {
    // clear the component container
    this.clearComponentContainer();

    // normal background
    document.body.className = '';

    // create a header
    this.componentContainer.appendChild(
      Elements.createHeader({
        className: 'title',
        textContent: 'Meldet',
      }),
    );

    // create a nav bar
    this.componentContainer.appendChild(
      Elements.createNav(),
    );

    // create an error container
    const errorFill = Elements.createParagraph({
      className: 'errorContainer fill',
    });

    // create a label
    const titleLabel = Elements.createLabel({
      className: 'lightLabel',
      htmlFor: 'title',
      innerHTML: 'Title Or Keyword',
    });

    // create an input
    const titleInput = Elements.createInput({
      className: 'lightInput',
      type: 'text',
      name: 'title',
      id: 'title',
      placeholder: 'Give a name fot the meldet',
    });

    // create a label
    const addresLabel = Elements.createLabel({
      className: 'lightLabel',
      htmlFor: 'adress',
      innerHTML: 'Adress',
    });

    // create an input
    const adressInput = Elements.createInput({
      className: 'lightInput',
      type: 'text',
      name: 'adress',
      id: 'adress',
      placeholder: 'Adress of harassment',
    });

    // create a label
    const descriptionLabel = Elements.createLabel({
      className: 'lightLabel',
      htmlFor: 'description',
      innerHTML: 'Description (optional)',
    });

    // create an input
    const descriptionInput = Elements.createTextarea({
      className: 'lightInput',
      name: 'description',
      id: 'description',
      cols: '30',
      rows: '10',
      placeholder: 'Tell us about the harassment',
    });

    // create a label
    const categoryLabel = Elements.createLabel({
      className: 'lightLabel',
      htmlFor: 'category',
      innerHTML: 'Category',
    });

    // create an input
    const categoryInput = Elements.createInput({
      className: 'lightInput',
      type: 'text',
      name: 'category',
      id: 'category',
      placeholder: 'Category of harassment',
    });

    // create a label
    const dateLabel = Elements.createLabel({
      className: 'lightLabel',
      htmlFor: 'date',
      innerHTML: 'Date',
    });

    // create an input
    const dateInput = Elements.createInput({
      className: 'lightInput',
      type: 'datetime-local',
      name: 'date',
      id: 'date',
    });

    // create a checkbox
    const checkInput = Elements.createInput({
      className: 'lightInput lightInput--check',
      type: 'checkbox',
      name: 'check',
      id: 'check',
    });

    // create a label
    const checkLabel = Elements.createLabel({
      className: 'newEvent__label newEvent__label--check',
      htmlFor: 'check',
      innerHTML: 'Tick this box to prove you are human',
    });

    // create a container (for the check)
    const checkContainer = Elements.createContainer({
      className: 'meldet__checkContainer',
      children: [checkInput, checkLabel],
    });

    // create an error container
    const errorContainer = Elements.createParagraph({
      className: 'errorContainer',
    });

    // create a button
    const addButton = Elements.createButton({
      className: 'secondary',
      textContent: 'Continue',
      type: 'submit',
      onClick: () => {
        const checkBox = document.getElementById('check');
        if (checkBox.checked === true) {
          const formData = new FormData(document.querySelector('.meldet__formAdd'));
          const title = formData.get('title');
          const adress = formData.get('adress');
          const description = formData.get('description');
          const category = formData.get('category');
          const date = formData.get('date');
          if (title && adress && description && category && date) {
            console.log(`title: ${title}\nadress: ${adress}\ndescription: ${description}\ncategory: ${category}\ndate: ${date}`);
            Router.getRouter().navigate('/dashboard');
          } else {
            const error = document.querySelector('form .errorContainer');
            error.innerHTML = '*Fill in everything!';
          }
        } else {
          const error = document.querySelector('form .errorContainer');
          error.innerHTML = '*check the box!';
        }
      },
    });

    // create a form
    const formMeldet = Elements.createForm({
      className: 'meldet__formAdd',
      children: [errorFill, titleLabel, titleInput, addresLabel, adressInput, descriptionLabel, descriptionInput, categoryLabel, categoryInput, dateLabel, dateInput, checkContainer, errorContainer, addButton],
    });

    // create a div
    this.componentContainer.appendChild(
      Elements.createContainer({
        className: 'meldet',
        children: [formMeldet],
      }),
    );

    // add a classname active for the current page
    this.componentContainer.getElementsByClassName('nav__link')[1].classList.add('nav__link--active');

    // return the meldet container
    return this.componentContainer;
  }
}

export default MeldetComponent;
