// The Component Parent

import Elements from './Elements';

class Component {
  constructor({
    name,
    routerPath,
  }) {
    this.name = name;
    this.routerPath = routerPath;
    this.componentContainer = this.createComponentContainer();
  }

  createComponentContainer() {
    return Elements.createContainer({
      id: `${this.name}Container`,
    });
  }

  clearComponentContainer() {
    while (this.componentContainer.firstChild) {
      this.componentContainer.removeChild(this.componentContainer.lastChild);
    }
  }
}

export default Component;
