// My Event Component

import Component from '../lib/Component';
import Events from '../lib/Event';
import Elements from '../lib/Elements';
import Router from '../router';

class EventComponent extends Component {
  constructor() {
    super({
      name: 'event',
      routerPath: '/event/:id',
    });
    this.model = {
      event: null,
    };
  }

  // load a certain event with id
  async loadProduct(id) {
    this.model.event = await Events.getById(id);
  }

  async renderAsync() {
    // load event
    await this.loadProduct(localStorage.getItem('eventId'));

    // destructure the data of the event
    const {
      photoUrl,
      title,
      startDate,
      endDate,
      street,
      city,
      description,
      organizer,
    } = this.model.event;

    // create a months array
    const monthNames = ['Januari', 'Februari', 'Maart', 'April', 'Mei', 'Juni',
      'Juli', 'Augustus', 'September', 'Oktober', 'November', 'December',
    ];

    // clear the component container
    this.clearComponentContainer();

    // normal background
    document.body.className = '';

    // create a header
    this.componentContainer.appendChild(
      Elements.createHeader({
        className: 'title',
        textContent: 'Samen Uit, Samen Thuis',
      }),
    );

    // create a nav bar
    this.componentContainer.appendChild(
      Elements.createNav(),
    );

    // create an icon arrow
    const back = Elements.createIcon({
      className: 'eventInd__icon fas fa-long-arrow-alt-left',
      onClick: () => {
        Router.getRouter().navigate('/dashboard');
      },
    });

    // create an img
    const img = Elements.createImage({
      className: 'eventInd__img',
      src: photoUrl,
      alt: title,
    });

    // create a title
    const titleEvent = Elements.createHeader({
      size: '2',
      className: 'eventInd__header',
      textContent: title,
    });

    // create a paragraph (adress)
    const organizerUser = Elements.createParagraph({
      className: 'eventInd__paragraph',
      innerHTML: `<i class="fas fa-user"></i>${organizer}`,
    });

    // create a line
    const lineOrganizer = Elements.createLine({
      className: 'eventInd__line',
    });

    // create a paragraph (adress)
    const adress = Elements.createParagraph({
      className: 'eventInd__paragraph',
      innerHTML: `<i class="fas fa-map-marker-alt"></i>${street}, ${city}`,
    });

    // create a line
    const lineAdress = Elements.createLine({
      className: 'eventInd__line',
    });

    // create a paragraph (date)
    const date = Elements.createParagraph({
      className: 'eventInd__paragraph',
      innerHTML: `<i class="fas fa-calendar-alt"></i>${startDate.toDate().getDate()} ${monthNames[startDate.toDate().getMonth()]} ${startDate.toDate().getFullYear()} - ${endDate.toDate().getDate()} ${monthNames[endDate.toDate().getMonth()]} ${endDate.toDate().getFullYear()}`,
    });

    // create a line
    const lineDate = Elements.createLine({
      className: 'eventInd__line',
    });

    // create a paragraph (time)
    const time = Elements.createParagraph({
      className: 'eventInd__paragraph',
      innerHTML: `<i class="fas fa-clock"></i>${startDate.toDate().getHours()}:${startDate.toDate().getMinutes()} - ${endDate.toDate().getHours()}:${endDate.toDate().getMinutes()}`,
    });

    // create a title
    const descriptionEvent = Elements.createHeader({
      size: '2',
      className: 'eventInd__header eventInd__header--second',
      textContent: 'About the event',
    });

    // create a description
    const descriptionText = Elements.createParagraph({
      className: 'eventInd__paragraph',
      textContent: description,
    });

    // create a title
    const container = Elements.createContainer({
      className: 'eventInd__container',
      children: [titleEvent, organizerUser, lineOrganizer, adress, lineAdress, date, lineDate, time, descriptionEvent, descriptionText],
    });

    // create a container
    this.componentContainer.appendChild(
      Elements.createContainer({
        className: 'eventInd',
        children: [back, img, container],
      }),
    );

    // return the event container
    return this.componentContainer;
  }
}

export default EventComponent;
