// My Login Component

import Auth from '../lib/Authentication';
import Component from '../lib/Component';
import Elements from '../lib/Elements';

class ForgotPasswordComponent extends Component {
  constructor() {
    super({
      name: 'forgotpassword',
      routerPath: '/forgotpassword',
    });
  }

  render() {
    // clear the component container
    this.clearComponentContainer();

    // blue background
    document.body.className = 'bg-blue';

    // create an image
    const banner = Elements.createImage({
      src: require('../img/banner.jpg'),
      alt: 'banner',
      className: 'header__banner',
    });

    // create a logo
    const logo = Elements.createImage({
      src: require('../img/logo.png'),
      alt: 'Samen Uit, Samen Thuis',
      className: 'header__logo',
    });

    // create a container
    this.componentContainer.appendChild(
      Elements.createContainer({
        className: 'header',
        children: [banner, logo],
      }),
    );

    // create a header
    const formHeader = Elements.createHeader({
      size: '2',
      className: 'authentication__header',
      textContent: 'Forgot Password',
    });

    // error container
    const errorContainer = Elements.createParagraph({
      className: 'errorContainer',
    });

    // create an input
    const inputEmail = Elements.createInput({
      className: 'darkInput',
      type: 'email',
      name: 'email',
      id: 'email',
      placeholder: 'Email',
    });

    // create a button (send)
    const signInButton = Elements.createButton({
      className: 'primary',
      textContent: 'Send',
      onClick: () => {
        Auth.forgot();
      },
    });

    // create a signup link
    const signIn = Elements.createLink({
      href: '/',
      className: 'authentication__sign authentication__sign--forgotPassword',
      innerHTML: 'Remembered it again? <span class="sign">Sign in here</span>',
    });

    // create a container
    const authentication = Elements.createForm({
      className: 'authentication',
      children: [
        formHeader, errorContainer, inputEmail, signInButton, signIn],
    });

    // create a form
    this.componentContainer.appendChild(
      Elements.createContainer({
        className: 'authenticationContainer authenticationContainer--forgotPassword',
        children: [authentication],
      }),
    );

    // return the forgot password container
    return this.componentContainer;
  }
}

export default ForgotPasswordComponent;
