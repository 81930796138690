// My New Event Component

import {
  getDownloadURL, getStorage, ref, uploadBytes,
} from 'firebase/storage';
import Component from '../lib/Component';
import Elements from '../lib/Elements';
import Events from '../lib/Event';

class NewEventComponent extends Component {
  constructor() {
    super({
      name: 'newEvent',
      routerPath: '/newEvent',
    });
  }

  // credits to Thibault Feraux for the uploadPhoto
  async uploadPhoto() {
    const formData = new FormData(document.querySelector('.newEvent__formAdd'));
    const photo = formData.get('file');
    if (photo.size === 0) {
      return '';
    }
    const storage = getStorage();
    const storageRef = ref(storage, photo.name);
    await uploadBytes(storageRef, photo).then(() => {
      getDownloadURL(storageRef).then((downloadUrl) => {
        this.photoUrl = downloadUrl;
        const uploaded = document.querySelector('.uploaded');
        uploaded.innerHTML = 'Uploaded!';
      });
    });
  }

  render() {
    // clear the component container
    this.clearComponentContainer();

    // normal background
    document.body.className = '';

    // create a header
    this.componentContainer.appendChild(
      Elements.createHeader({
        className: 'title',
        textContent: 'Create Event',
      }),
    );

    // create a nav bar
    this.componentContainer.appendChild(
      Elements.createNav(),
    );

    // create a label (for the file input)
    const imgLabel = Elements.createLabel({
      className: 'newEvent__label',
      htmlFor: 'file',
      innerHTML: '<i class="fa fa-camera"></i>',
    });

    // create a paragraph (text under img)
    const imgText = Elements.createParagraph({
      className: 'newEvent__text',
      textContent: 'Choose image',
    });

    // create an input
    const imgFile = Elements.createInput({
      className: 'newEvent__file',
      type: 'file',
      name: 'file',
      id: 'file',
      onChange: () => {
        this.uploadPhoto();
      },
    });

    // create a paragraph when image is uploaded
    const uploaded = Elements.createParagraph({
      className: 'uploaded',
    });

    // create an img import
    const image = Elements.createContainer({
      className: 'newEvent__import',
      children: [imgLabel, imgText, imgFile, uploaded],
    });

    // create a label
    const titleLabel = Elements.createLabel({
      className: 'lightLabel',
      htmlFor: 'title',
      innerHTML: 'Title',
    });

    // create an input
    const titleInput = Elements.createInput({
      className: 'lightInput',
      type: 'text',
      name: 'title',
      id: 'title',
      placeholder: 'Give a name for your event',
    });

    // create a label
    const descriptionLabel = Elements.createLabel({
      className: 'lightLabel',
      htmlFor: 'description',
      innerHTML: 'Description',
    });

    // create an input
    const descriptionInput = Elements.createTextarea({
      className: 'lightInput',
      name: 'description',
      id: 'description',
      cols: '30',
      rows: '10',
      placeholder: 'What is your event about?',
    });

    // create a label
    const streetLabel = Elements.createLabel({
      className: 'lightLabel',
      htmlFor: 'street',
      innerHTML: 'Street & Number',
    });

    // create an input
    const streetInput = Elements.createInput({
      className: 'lightInput',
      type: 'text',
      name: 'street',
      id: 'street',
      placeholder: 'Your street name with number',
    });

    // create a label
    const cityLabel = Elements.createLabel({
      className: 'lightLabel',
      htmlFor: 'city',
      innerHTML: 'City',
    });

    // create an input
    const cityInput = Elements.createInput({
      className: 'lightInput',
      type: 'text',
      name: 'city',
      id: 'city',
      placeholder: 'Your city',
    });

    // create a label
    const startDateLabel = Elements.createLabel({
      className: 'lightLabel',
      htmlFor: 'startDate',
      innerHTML: 'Start event',
    });

    // create an input
    const startDateInput = Elements.createInput({
      className: 'lightInput',
      type: 'datetime-local',
      name: 'startDate',
      id: 'startDate',
    });

    // create a label
    const endDateLabel = Elements.createLabel({
      className: 'lightLabel',
      htmlFor: 'endDate',
      innerHTML: 'End event',
    });

    // create an input
    const endDateInput = Elements.createInput({
      className: 'lightInput',
      type: 'datetime-local',
      name: 'endDate',
      id: 'endDate',
    });

    // create an error container
    const errorContainer = Elements.createParagraph({
      className: 'errorContainer',
    });

    // create a button
    const addButton = Elements.createButton({
      className: 'secondary',
      textContent: 'Continue',
      onClick: () => {
        Events.newEvent(this.photoUrl);
      },
    });

    // create a form
    const formAdd = Elements.createForm({
      className: 'newEvent__formAdd',
      children: [image, titleLabel, titleInput, descriptionLabel, descriptionInput, streetLabel, streetInput, cityLabel, cityInput, startDateLabel, startDateInput, endDateLabel, endDateInput, errorContainer, addButton],
    });

    // create a div
    this.componentContainer.appendChild(
      Elements.createContainer({
        className: 'newEvent',
        children: [formAdd],
      }),
    );

    // add a classname active for the current page
    this.componentContainer.getElementsByClassName('nav__link')[2].classList.add('nav__link--add');

    // return the new event container
    return this.componentContainer;
  }
}

export default NewEventComponent;
