// My Register Details Component

import {
  getDownloadURL, getStorage, ref, uploadBytes,
} from 'firebase/storage';
import Component from '../lib/Component';
import Elements from '../lib/Elements';
import User from '../lib/User';

class RegisterdetailComponent extends Component {
  constructor() {
    super({
      name: 'registerdetail',
      routerPath: '/registerdetail',
    });
    this.photoUrl = '';
  }

  // credits to Thibault Feraux for the uploadPhoto
  async uploadPhoto() {
    const formData = new FormData(document.querySelector('.authentication'));
    const photo = formData.get('file');
    if (photo.size === 0) {
      return '';
    }
    const storage = getStorage();
    const storageRef = ref(storage, photo.name);
    await uploadBytes(storageRef, photo).then(() => {
      getDownloadURL(storageRef).then((downloadUrl) => {
        this.photoUrl = downloadUrl;
        const uploaded = document.querySelector('.uploaded');
        uploaded.innerHTML = 'Uploaded!';
      });
    });
  }

  render() {
    // clear the component container
    this.clearComponentContainer();

    // blue background
    document.body.className = 'bg-blue';

    // create an image
    const banner = Elements.createImage({
      src: require('../img/banner.jpg'),
      alt: 'banner',
      className: 'header__banner',
    });

    // create a logo
    const logo = Elements.createImage({
      src: require('../img/logo.png'),
      alt: 'Samen Uit, Samen Thuis',
      className: 'header__logo',
    });

    // create a container
    this.componentContainer.appendChild(
      Elements.createContainer({
        className: 'header header--registerdetail',
        children: [banner, logo],
      }),
    );

    // create a label (for the file input)
    const avatarLabel = Elements.createLabel({
      className: 'avatar__label',
      htmlFor: 'file',
      innerHTML: '<i class="fa fa-image"></i>',
    });

    // create a paragraph (text under avatar)
    const avatarText = Elements.createParagraph({
      className: 'avatar__text',
      textContent: 'Choose avatar',
    });

    // create an input
    const avatarFile = Elements.createInput({
      className: 'avatar__file',
      type: 'file',
      name: 'file',
      id: 'file',
      onChange: () => {
        this.uploadPhoto();
      },
    });

    // create a paragraph when image is uploaded
    const uploaded = Elements.createParagraph({
      className: 'uploaded',
    });

    // create a container (avatar)
    const avatar = Elements.createContainer({
      className: 'avatar',
      children: [avatarLabel, avatarText, avatarFile, uploaded],
    });

    // create an input
    const inputFirstname = Elements.createInput({
      className: 'darkInput',
      type: 'text',
      name: 'fname',
      id: 'fname',
      placeholder: 'Firstname',
      required: true,
    });

    // create an input
    const inputLastname = Elements.createInput({
      className: 'darkInput',
      type: 'text',
      name: 'lname',
      id: 'lname',
      placeholder: 'Lastname',
    });

    // create an input
    const inputUsername = Elements.createInput({
      className: 'darkInput',
      type: 'text',
      name: 'uname',
      id: 'uname',
      placeholder: 'Username',
    });

    // create an input
    const inputNumber = Elements.createInput({
      className: 'darkInput',
      type: 'tel',
      name: 'number',
      id: 'number',
      placeholder: 'Phone number',
    });

    // create an error container
    const errorContainer = Elements.createParagraph({
      className: 'errorContainer',
    });

    // create a button (sign up)
    const signInButton = Elements.createButton({
      className: 'primary',
      textContent: 'Sign Up',
      onClick: () => {
        User.updateUser('.authentication', this.photoUrl);
      },
    });

    // create a container
    const authentication = Elements.createForm({
      className: 'authentication',
      children: [
        avatar, inputFirstname, inputLastname, inputUsername, inputNumber, errorContainer, signInButton],
    });

    // create a form
    this.componentContainer.appendChild(
      Elements.createContainer({
        className: 'authenticationContainer',
        children: [
          authentication],
      }),
    );

    // return the registerdetail container
    return this.componentContainer;
  }
}

export default RegisterdetailComponent;
