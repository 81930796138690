// My Elements Helper

import Router from '../router';

const Elements = {
  createNavigation({ className = '', children = [] }) {
    const nav = document.createElement('nav');
    nav.className = className;
    if (children.length) {
      const ul = document.createElement('ul');
      ul.className = 'nav__list';
      children.forEach((child) => {
        if (child instanceof Element) {
          const li = document.createElement('li');
          li.className = 'nav__item';
          li.appendChild(child);
          ul.appendChild(li);
        }
      });
      nav.appendChild(ul);
    }
    return nav;
  },

  createButton({
    id = '', className = '', innerHTML = '', textContent = '', children = [], onClick = null, type = '',
  }) {
    const button = document.createElement('button');
    if (id) button.id = id;
    button.className = className;
    if (textContent) button.textContent = textContent;
    if (innerHTML) button.innerHTML = innerHTML;
    if (type) button.type = type;
    if (children.length) {
      children.forEach((child) => {
        if (child instanceof Element) {
          button.appendChild(child);
        }
      });
    }
    if (onClick) {
      button.addEventListener('click', (e) => {
        e.preventDefault();
        onClick();
      });
    }
    return button;
  },

  createContainer({
    className = '', id = '', innerHTML = '', children = [],
  }) {
    const container = document.createElement('div');
    if (id) container.setAttribute('id', id);
    container.className = className;
    container.innerHTML = innerHTML;
    if (children.length) {
      children.forEach((child) => {
        if (child instanceof Element) {
          container.appendChild(child);
        }
      });
    }
    return container;
  },

  createHeader({ size = 1, textContent = '', className = '' }) {
    if (size < 1 || size > 6) return null;
    const header = document.createElement(`h${size}`);
    header.textContent = textContent;
    header.className = className;
    return header;
  },

  createLink({
    href, innerHTML = '', target = '_self', className = '',
  }) {
    const a = document.createElement('a');
    if (href) a.href = href;
    a.setAttribute('data-navigo', '');
    a.innerHTML = innerHTML;
    a.target = target;
    a.className = className;
    return a;
  },

  createParagraph({ textContent = '', innerHTML = '', className = '' }) {
    const p = document.createElement('p');
    if (textContent) p.textContent = textContent;
    if (innerHTML) p.innerHTML = innerHTML;
    p.className = className;
    return p;
  },

  createSpan({ textContent }) {
    const span = document.createElement('span');
    span.textContent = textContent;
    return span;
  },

  createImage({
    className = '', src, alt = '', onClick = null,
  }) {
    const img = document.createElement('img');
    img.className = className;
    img.src = src;
    img.alt = alt;
    if (onClick) {
      img.addEventListener('click', (e) => {
        e.preventDefault();
        onClick();
      });
    }
    return img;
  },

  createIcon({ className = '', onClick = null }) {
    const i = document.createElement('i');
    i.className = className;
    if (onClick) {
      i.addEventListener('click', (e) => {
        e.preventDefault();
        onClick();
      });
    }
    return i;
  },

  createLine({ className = '' }) {
    const line = document.createElement('hr');
    line.className = className;
    return line;
  },

  createForm({ className = '', action = '', children = [] }) {
    const form = document.createElement('form');
    form.className = className;
    if (action) form.action = action;
    if (children.length) {
      children.forEach((child) => {
        if (child instanceof Element) {
          form.appendChild(child);
        }
      });
    }
    return form;
  },

  createLabel({ className = '', htmlFor = '', innerHTML = '' }) {
    const label = document.createElement('label');
    label.className = className;
    label.htmlFor = htmlFor;
    label.innerHTML = innerHTML;
    return label;
  },

  createInput({
    className = '', type, name, id, placeholder = '', value = '', min = '', onChange = null, required,
  }) {
    const input = document.createElement('input');
    input.className = className;
    input.type = type;
    if (input.type === 'file') {
      input.accept = 'image/png, image/gif, image/jpeg';
    }
    input.name = name;
    input.id = id;
    if (required) input.required = true;
    if (placeholder) input.placeholder = placeholder;
    if (value) input.value = value;
    if (min) input.min = min;
    if (onChange) {
      input.addEventListener('change', (e) => {
        e.preventDefault();
        onChange();
      });
    }
    return input;
  },

  createTextarea({
    className = '', name, id, cols = '', rows = '', placeholder = '',
  }) {
    const textarea = document.createElement('textarea');
    textarea.className = className;
    textarea.name = name;
    textarea.id = id;
    textarea.cols = cols;
    textarea.rows = rows;
    textarea.placeholder = placeholder;
    return textarea;
  },

  createList({ items = [], ordered = false }) {
    const list = document.createElement(ordered ? 'ol' : 'ul');
    items.forEach(({ textContent, href, onClick }) => {
      const li = document.createElement('li');
      if (!href && !onClick) {
        li.textContent = textContent;
      } else if (href) {
        li.appendChild(Elements.createLink({
          textContent,
          onClick,
        }));
      } else {
        li.appendChild(Elements.createButton({
          textContent,
          href,
        }));
      }
      list.appendChild(li);
    });
    return list;
  },

  createNav() {
    // create a nav link (home)
    const navHome = Elements.createButton({
      className: 'nav__link',
      innerHTML: '<i class="fa fa-home home"></i>',
      onClick: () => {
        Router.getRouter().navigate('/dashboard');
      },
    });

    // create a nav link (meldet)
    const navMeldet = this.createButton({
      className: 'nav__link',
      innerHTML: '<i class="fa fa-file file"></i>',
      onClick: () => {
        Router.getRouter().navigate('/meldet');
      },
    });

    // create a nav link (new event)
    const navAdd = this.createButton({
      className: 'nav__link nav__link--large',
      innerHTML: '<i class="fa fa-plus-circle add"></i>',
      onClick: () => {
        Router.getRouter().navigate('/newEvent');
      },
    });

    // create a nav link (notifations)
    const navNot = this.createButton({
      className: 'nav__link',
      innerHTML: '<i class="fa fa-bell bell"></i>',
      onClick: () => {
        Router.getRouter().navigate('/notifications');
      },
    });

    // create a nav link (settings)
    const navSettings = this.createButton({
      className: 'nav__link',
      innerHTML: '<i class="fa fa-user user"></i>',
      onClick: () => {
        Router.getRouter().navigate('/settings');
      },
    });

    const nav = this.createNavigation({
      className: 'nav',
      children: [navHome, navMeldet, navAdd, navNot, navSettings],
    });
    return nav;
  },
};

export default Elements;
