// My Dashboard Component

import Component from '../lib/Component';
import Events from '../lib/Event';
import Elements from '../lib/Elements';
import Router from '../router';
import Auth from '../lib/Authentication';

class DashboardComponent extends Component {
  constructor() {
    super({
      name: 'dashboard',
      routerPath: '/dashboard',
    });
    this.model = {
      events: [],
    };
  }

  // load all the data of the events
  async loadEvents() {
    const events = await Events.getAll();
    this.model.events = events.map((event) => ({
      onClick: () => {
        localStorage.setItem('eventId', event.id);
        Router.getRouter().navigate(`/event/${event.id}`);
      },
      textContent: event.title,
      datetime: event.startDate.toDate(),
      photoUrl: event.photoUrl,
    }));
  }

  async renderAsync() {
    // clear the component container
    this.clearComponentContainer();

    // load events
    await this.loadEvents();

    // normal background
    document.body.className = '';

    // when we are logged out
    Auth.loggedOut('/');

    // create a header
    this.componentContainer.appendChild(
      Elements.createHeader({
        className: 'title',
        textContent: 'Samen Uit, Samen Thuis',
      }),
    );

    // create a nav bar
    this.componentContainer.appendChild(
      Elements.createNav(),
    );

    // create a link (my events)
    const myEvents = Elements.createButton({
      className: 'dashboard__events',
      innerHTML: 'My Events',
      onClick: () => {
        Router.getRouter().navigate('/dashboard');
      },
    });

    // create a link (invited events)
    const invitedEvents = Elements.createButton({
      className: 'dashboard__events',
      innerHTML: 'Invited',
      onClick: () => {
        Router.getRouter().navigate('/dashboard/invited');
      },
    });

    // create a container (links)
    const links = Elements.createContainer({
      className: 'dashboard__links',
      children: [myEvents, invitedEvents],
    });

    // create a container for the links
    const dashboard = Elements.createContainer({
      className: 'dashboard',
      children: [links],
    });

    // for eah loop for all the events
    this.model.events.forEach((event) => {
      // create event date
      const headerEvent = Elements.createHeader({
        size: '3',
        className: 'event__date',
        textContent: `${event.datetime.getDate()} / ${event.datetime.getMonth() + 1}`,
      });

      // create event img
      const imgEvent = Elements.createImage({
        className: 'event__img',
        src: event.photoUrl,
        alt: 'image',
        onClick: event.onClick,
      });

      // create event title
      const titleEvent = Elements.createParagraph({
        className: 'event__title',
        textContent: event.textContent,
      });

      // create event item
      const eventItem = Elements.createContainer({
        className: 'event__item',
        children: [headerEvent, imgEvent, titleEvent],
      });

      // create event
      const eventContainer = Elements.createContainer({
        className: 'event',
        children: [eventItem],
      });

      // add the eventcontainer to the dashboard
      dashboard.appendChild(
        eventContainer,
      );
    });

    // append the dashboard to the main
    this.componentContainer.appendChild(
      dashboard,
    );

    // add a classname active for the current page
    this.componentContainer.getElementsByClassName('nav__link')[0].classList.add('nav__link--active');
    this.componentContainer.getElementsByClassName('dashboard__events')[0].classList.add('dashboard__events--active');

    // return the dashboard container
    return this.componentContainer;
  }
}

export default DashboardComponent;
