// My Login Component

import Auth from '../lib/Authentication';
import Component from '../lib/Component';
import Elements from '../lib/Elements';
import Router from '../router';

class LoginComponent extends Component {
  constructor() {
    super({
      name: 'login',
      routerPath: '/',
    });
  }

  render() {
    // clear the component container
    this.clearComponentContainer();

    // when we are logged in
    Auth.loggedIn('/dashboard');

    // blue background
    document.body.className = 'bg-blue';

    // create an image
    const banner = Elements.createImage({
      src: require('../img/banner.jpg'),
      alt: 'banner',
      className: 'header__banner',
    });

    // create a logo
    const logo = Elements.createImage({
      src: require('../img/logo.png'),
      alt: 'Samen Uit, Samen Thuis',
      className: 'header__logo',
    });

    // create a container
    this.componentContainer.appendChild(
      Elements.createContainer({
        className: 'header',
        children: [banner, logo],
      }),
    );

    // create a header
    const formHeader = Elements.createHeader({
      size: '2',
      className: 'authentication__header',
      textContent: 'Sign In',
    });

    // error container
    const errorContainer = Elements.createParagraph({
      className: 'errorContainer',
    });

    // create an input
    const inputEmail = Elements.createInput({
      className: 'darkInput',
      type: 'email',
      name: 'email',
      id: 'email',
      placeholder: 'Email',
    });

    // create an input
    const inputPassword = Elements.createInput({
      className: 'darkInput',
      type: 'password',
      name: 'password',
      id: 'password',
      placeholder: 'Password',
    });

    // create a paragraph (forgot password)
    const formForgot = Elements.createButton({
      className: 'authentication__forgot',
      textContent: 'Forgot Password?',
      type: 'button',
      onClick: () => {
        Router.getRouter().navigate('/forgotpassword');
      },
    });

    // create a button (sign in)
    const signInButton = Elements.createButton({
      className: 'primary',
      textContent: 'Sign In',
      onClick: () => {
        Auth.login();
      },
    });

    // create a paragraph (OR)
    const or = Elements.createSpan({
      textContent: 'OR',
    });

    // create a line
    const line = Elements.createContainer({
      className: 'authentication__line',
      children: [or],
    });

    // create facebook link
    const facebook = Elements.createButton({
      className: 'socials__ind socials__ind--facebook',
      innerHTML: '<i class="fa fa-facebook"></i>',
      onClick: () => {
        Auth.loginWithFacebook();
      },
    });

    // create twitter link
    const twitter = Elements.createButton({
      className: 'socials__ind socials__ind--twitter',
      innerHTML: '<i class="fa fa-twitter"></i>',
      onClick: () => {
        Auth.loginWithTwitter();
      },
    });

    // create google link
    const google = Elements.createButton({
      className: 'socials__ind socials__ind--google',
      innerHTML: '<i class="fa fa-google"></i>',
      onClick: () => {
        Auth.loginWithGoogle();
      },
    });

    // create a socials
    const socials = Elements.createContainer({
      className: 'socials',
      children: [facebook, twitter, google],
    });

    // create a signup link
    const signUp = Elements.createLink({
      href: 'register',
      className: 'authentication__sign',
      innerHTML: 'Don\'t have an account? <span class="sign">Sign up</span>',
    });

    // create a container
    const authentication = Elements.createForm({
      className: 'authentication',
      children: [
        formHeader, errorContainer, inputEmail, inputPassword, formForgot, signInButton, line, socials, signUp],
    });

    // create a form
    this.componentContainer.appendChild(
      Elements.createContainer({
        className: 'authenticationContainer',
        children: [authentication],
      }),
    );

    // return the login container
    return this.componentContainer;
  }
}

export default LoginComponent;
