import LoginComponent from './Login';
import ForgotPasswordComponent from './ForgotPassword';
import RegisterComponent from './Register';
import RegisterdetailComponent from './Registerdetail';
import DashboardComponent from './Dashboard';
import InvitedComponent from './Invited';
import EventComponent from './Event';
import MeldetComponent from './Meldet';
import NewEventComponent from './NewEvent';
import NotificationsComponent from './Notifications';
import SettingsComponent from './Settings';
import ProfileComponent from './Profile';

export {
  LoginComponent,
  ForgotPasswordComponent,
  RegisterComponent,
  RegisterdetailComponent,
  DashboardComponent,
  InvitedComponent,
  EventComponent,
  MeldetComponent,
  NewEventComponent,
  NotificationsComponent,
  SettingsComponent,
  ProfileComponent,
};
