// My Dashboard Component

import Component from '../lib/Component';
import Elements from '../lib/Elements';
import Router from '../router';
import Auth from '../lib/Authentication';

class InvitedComponent extends Component {
  constructor() {
    super({
      name: 'invited',
      routerPath: '/dashboard/invited',
    });
  }

  render() {
    // clear the component container
    this.clearComponentContainer();

    // normal background
    document.body.className = '';

    // when we are logged out
    Auth.loggedOut('/');

    // create a header
    this.componentContainer.appendChild(
      Elements.createHeader({
        className: 'title',
        textContent: 'Samen Uit, Samen Thuis',
      }),
    );

    // create nav
    this.componentContainer.appendChild(
      Elements.createNav(),
    );

    // create a link (my events)
    const myEvents = Elements.createButton({
      className: 'dashboard__events',
      innerHTML: 'My Events',
      onClick: () => {
        Router.getRouter().navigate('/dashboard');
      },
    });

    // create a link (my events)
    const invitedEvents = Elements.createButton({
      className: 'dashboard__events',
      innerHTML: 'Invited',
      onClick: () => {
        Router.getRouter().navigate('/dashboard/invited');
      },
    });

    // create a div (links)
    const links = Elements.createContainer({
      className: 'dashboard__links',
      children: [myEvents, invitedEvents],
    });

    const dashboard = Elements.createContainer({
      className: 'dashboard',
      children: [links],
    });

    // create event header
    const headerEvent = Elements.createHeader({
      size: '3',
      className: 'event__date',
      textContent: '20 / 3',
    });

    // create event img
    const imgEvent = Elements.createImage({
      className: 'event__img',
      src: 'https://images.pexels.com/photos/3171837/pexels-photo-3171837.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260',
      alt: 'image',
    });

    // create event img
    const titleEvent = Elements.createParagraph({
      className: 'event__title',
      textContent: 'KOTFEEST',
    });

    // create event item
    const eventItem = Elements.createContainer({
      className: 'event__item',
      children: [headerEvent, imgEvent, titleEvent],
    });

    // create event
    const eventContainer = Elements.createContainer({
      className: 'event',
      children: [eventItem],
    });

    dashboard.appendChild(
      eventContainer,
    );

    // create a div
    this.componentContainer.appendChild(
      dashboard,
    );

    this.componentContainer.getElementsByClassName('nav__link')[0].classList.add('nav__link--active');
    this.componentContainer.getElementsByClassName('dashboard__events')[1].classList.add('dashboard__events--active');

    // return the register container
    return this.componentContainer;
  }
}

export default InvitedComponent;
