// My Notifications Component

import Component from '../lib/Component';
import Elements from '../lib/Elements';

class NotificationsComponent extends Component {
  constructor() {
    super({
      name: 'notifications',
      routerPath: '/notifications',
    });
  }

  render() {
    // clear the component container
    this.clearComponentContainer();

    // normal background
    document.body.className = '';

    // create a header
    this.componentContainer.appendChild(
      Elements.createHeader({
        className: 'title',
        textContent: 'Notifications',
      }),
    );

    // create a nav bar
    this.componentContainer.appendChild(
      Elements.createNav(),
    );

    // create a delete button
    const notificationDelete = Elements.createButton({
      className: 'notification__delete',
      innerHTML: '<i class="fa fa-times"></i>',
      onClick: () => {
        document.getElementById('notification').style.display = 'none';
      },
    });

    // create a date
    const notificationDate = Elements.createParagraph({
      className: 'notification__date',
      textContent: '15/01',
    });

    // create a name
    const notificationName = Elements.createParagraph({
      className: 'notification__name',
      textContent: 'Bram Licoln',
    });

    // create an event
    const notificationEvent = Elements.createParagraph({
      className: 'notification__event',
      textContent: 'Birthday Party',
    });

    // create a time
    const notificationTime = Elements.createParagraph({
      className: 'notification__time',
      textContent: '8:30 PM to 11:30 PM',
    });

    // create a info container
    const notificationInfo = Elements.createContainer({
      className: 'notification__info',
      children: [notificationName, notificationEvent, notificationTime],
    });

    // create an accept
    const notificationAccept = Elements.createContainer({
      className: 'notification__accept',
      innerHTML: '<i class="fa fa-check"></i>',
    });

    // create a container
    const notificationContainer = Elements.createContainer({
      className: 'notification__item',
      children: [notificationDelete, notificationDate, notificationInfo, notificationAccept],
    });

    // create a container
    this.componentContainer.appendChild(
      Elements.createContainer({
        id: 'notification',
        className: 'notification',
        children: [notificationContainer],
      }),
    );

    // add a classname active for the current page
    this.componentContainer.getElementsByClassName('nav__link')[3].classList.add('nav__link--active');

    // return the notifications container
    return this.componentContainer;
  }
}

export default NotificationsComponent;
