// The App Wrapper

import Component from './lib/Component';
import Router from './router';
import initFirebase from './lib/firebase';

class App {
  constructor(parent) {
    this.parent = parent;
    this.components = [];
    initFirebase();
  }

  clearContainer(container) {
    while (container.firstChild) {
      container.removeChild(container.lastChild);
    }
  }

  addComponent(component) {
    if (!(component instanceof Component)) return;

    // get the name from our component
    const { name, routerPath } = component;

    // add to internal class
    this.components.push(component);

    // add to router
    Router.getRouter().on(routerPath, (props) => {
      this.showComponent({
        props,
        name,
      });
    }).resolve();
  }

  showComponent({ name, props }) {
    const foundComponent = this.components.find((component) => component.name === name);

    if (foundComponent) {
      this.clearContainer(this.parent);
      if (props) foundComponent.props = props;

      if (foundComponent.render) {
        this.parent.appendChild(foundComponent.render());
      }

      if (foundComponent.renderAsync) {
        foundComponent
          .renderAsync()
          .then((renderedComponent) => {
            this.parent.appendChild(renderedComponent);
          })
          .catch((error) => {
            console.log(error.message);
            this.clearContainer(this.portal);
          });
      }
    }
  }
}

export default App;
