// My Settings Component
import {
  getDownloadURL, getStorage, ref, uploadBytes,
} from 'firebase/storage';
import Component from '../lib/Component';
import Elements from '../lib/Elements';
import Router from '../router';
import User from '../lib/User';

class ProfileComponent extends Component {
  constructor() {
    super({
      name: 'profile',
      routerPath: '/settings/profile',
    });
  }

  // credits to Thibault Feraux for the uploadPhoto
  async uploadPhoto() {
    const formData = new FormData(document.querySelector('.profileEdit'));
    const photo = formData.get('file');
    if (photo.size === 0) {
      return '';
    }
    const storage = getStorage();
    const storageRef = ref(storage, photo.name);
    await uploadBytes(storageRef, photo).then(() => {
      getDownloadURL(storageRef).then((downloadUrl) => {
        this.photoUrl = downloadUrl;
        const uploaded = document.querySelector('.uploadedBlack');
        uploaded.innerHTML = 'Uploaded!';
      });
    });
  }

  async renderAsync() {
    // clear the component container
    this.clearComponentContainer();

    // normal background
    document.body.className = '';

    // get data of the signed in user
    const userData = await User.loadUser();
    const userName = userData.name.split(' ');

    // create a header
    this.componentContainer.appendChild(
      Elements.createHeader({
        className: 'title',
        textContent: 'Edit Profile',
      }),
    );

    // create a nav bar
    this.componentContainer.appendChild(
      Elements.createNav(),
    );

    // create a label
    const avatarLabel = Elements.createLabel({
      className: 'avatar__label',
      htmlFor: 'file',
      innerHTML: '<i class="fa fa-image"></i>',
    });

    // create an input
    const avatarFile = Elements.createInput({
      className: 'avatar__file',
      type: 'file',
      name: 'file',
      id: 'file',
      onChange: () => {
        this.uploadPhoto();
      },
    });

    // create a paragraph when image is uploaded
    const uploaded = Elements.createParagraph({
      className: 'uploadedBlack',
    });

    // create a container (avatar)
    const avatar = Elements.createContainer({
      className: 'avatar avatar--profile',
      children: [avatarLabel, avatarFile, uploaded],
    });

    // create a label
    const labelFirstname = Elements.createLabel({
      className: 'transparentLabel',
      htmlFor: 'fname',
      innerHTML: 'Firstname',
    });

    // create an input
    const inputFirstname = Elements.createInput({
      className: 'transparentInput',
      type: 'text',
      name: 'fname',
      id: 'fname',
      value: userName[0],
    });

    // create a label
    const labelLastname = Elements.createLabel({
      className: 'transparentLabel',
      htmlFor: 'fname',
      innerHTML: 'Lastname',
    });

    // create an input
    const inputLastname = Elements.createInput({
      className: 'transparentInput',
      type: 'text',
      name: 'lname',
      id: 'lname',
      value: userName.slice(1).join(' '),
    });

    // create a label
    const labelUsername = Elements.createLabel({
      className: 'transparentLabel',
      htmlFor: 'fname',
      innerHTML: 'Username',
    });

    // create an input
    const inputUsername = Elements.createInput({
      className: 'transparentInput',
      type: 'text',
      name: 'uname',
      id: 'uname',
      value: userData.uname,
    });

    // create a label
    const labelNumber = Elements.createLabel({
      className: 'transparentLabel',
      htmlFor: 'fname',
      innerHTML: 'Phone number',
    });

    // create an input
    const inputNumber = Elements.createInput({
      className: 'transparentInput',
      type: 'tel',
      name: 'number',
      id: 'number',
      value: userData.number,
    });

    // create a label
    const labelEmail = Elements.createLabel({
      className: 'transparentLabel',
      htmlFor: 'fname',
      innerHTML: 'Email',
    });

    // create an input
    const inputEmail = Elements.createParagraph({
      className: 'profileEdit__email',
      textContent: userData.email,
    });

    // create a button
    const editButton = Elements.createButton({
      className: 'primary',
      textContent: 'Edit',
      onClick: () => {
        User.updateUser('.profileEdit', this.photoUrl);
        Router.getRouter().navigate('/settings');
      },
    });

    // create a div (profile)
    const profile = Elements.createForm({
      className: 'profileEdit',
      children: [
        avatar, labelFirstname, inputFirstname, labelLastname, inputLastname, labelUsername, inputUsername, labelNumber, inputNumber, labelEmail, inputEmail, editButton,
      ],
    });

    // create an icon arrow
    const iconArrow = Elements.createIcon({
      className: 'profileData__icon fa fa-chevron-left',
      onClick: () => {
        Router.getRouter().navigate('/settings');
      },
    });

    // create a container
    this.componentContainer.appendChild(
      Elements.createContainer({
        className: 'profileData',
        children: [iconArrow, profile],
      }),
    );

    // return the profile container
    return this.componentContainer;
  }
}

export default ProfileComponent;
