// My Settings Component

import Component from '../lib/Component';
import Elements from '../lib/Elements';
import Router from '../router';
import User from '../lib/User';
import Auth from '../lib/Authentication';

class SettingsComponent extends Component {
  constructor() {
    super({
      name: 'settings',
      routerPath: '/settings',
    });
  }

  async renderAsync() {
    // clear the component container
    this.clearComponentContainer();

    // normal background
    document.body.className = '';

    // get data of the signed in user
    const userData = await User.loadUser();

    // create a header
    this.componentContainer.appendChild(
      Elements.createHeader({
        className: 'title',
        textContent: 'Settings',
      }),
    );

    // create a nav bar
    this.componentContainer.appendChild(
      Elements.createNav(),
    );

    // create an avatar img
    const profileImg = Elements.createImage({
      className: 'profile__img',
      src: userData.photoUrl,
      alt: 'avatar',
    });

    // create a paragraph (name)
    const profileName = Elements.createParagraph({
      className: 'profile__name',
      textContent: userData.name,
    });

    // create a paragraph (username)
    const profileUsername = Elements.createParagraph({
      className: 'profile__username',
      textContent: `@${userData.uname}`,
    });

    // create a div (profile)
    const profile = Elements.createContainer({
      className: 'profile',
      children: [profileImg, profileName, profileUsername],
    });

    // create a title
    const titleAcc = Elements.createHeader({
      size: '2',
      className: 'accountSettings__title',
      textContent: 'Account',
    });

    // create a button (edit profile)
    const profileData = Elements.createButton({
      className: 'account',
      innerHTML: '<i class="fas fa-user circle profile"></i></div> Edit Profile <i class="fa fa-chevron-right right"></i>',
      onClick: () => {
        Router.getRouter().navigate('/settings/profile');
      },
    });

    // create a button (delete profile)
    const deleter = Elements.createButton({
      className: 'account',
      innerHTML: '<i class="fas fa-trash circle delete"></i></div> Delete Account <i class="fa fa-chevron-right right"></i>',
      onClick: () => {
        User.deleteUser();
      },
    });

    // create a button (sign out)
    const signout = Elements.createButton({
      className: 'account',
      innerHTML: '<i class="fa fa-sign-out circle signout"></i></div> Sign Out <i class="fa fa-chevron-right right"></i>',
      onClick: () => {
        Auth.logout();
      },
    });

    // create a container
    const accountSettings = Elements.createContainer({
      className: 'accountSettings',
      children: [titleAcc, profileData, deleter, signout],
    });

    // create a container
    this.componentContainer.appendChild(
      Elements.createContainer({
        className: 'settings',
        children: [profile, accountSettings],
      }),
    );

    this.componentContainer.getElementsByClassName('nav__link')[4].classList.add('nav__link--active');

    // return the settings container
    return this.componentContainer;
  }
}

export default SettingsComponent;
