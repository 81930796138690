// Get Data Events

import {
  getFirestore, collection, query, where, getDocs, doc, getDoc, addDoc, orderBy,
} from 'firebase/firestore';
import Router from '../router';

const Events = {
  // get all events
  getAll: async () => {
    // get firestore
    const db = getFirestore();

    // get uid
    const uid = localStorage.getItem('uid');

    // define query
    const q = query(collection(db, 'events'), where('uid', '==', uid), orderBy('startDate', 'asc'));

    // get the query snapshot
    const querySnapshot = await getDocs(q);

    // loop over all documents
    return querySnapshot.docs.map((docInd) => (
      {
        ...docInd.data(),
        id: docInd.id,
      }
    ));
  },

  // get event by a certain id
  getById: async (id) => {
    const db = getFirestore();
    const docRef = doc(db, 'events', id);
    const event = (await getDoc(docRef)).data();
    return event;
  },

  // create a new event
  newEvent: async (photoURL) => {
    const formData = new FormData(document.querySelector('.newEvent__formAdd'));
    const titleForm = formData.get('title');
    const descriptionForm = formData.get('description');
    const streetForm = formData.get('street');
    const cityForm = formData.get('city');
    const startDate = formData.get('startDate');
    const endDate = formData.get('endDate');
    const uidNr = localStorage.getItem('uid');

    const db = getFirestore();
    const docRef = doc(db, 'users', uidNr);
    const docSnap = await getDoc(docRef);
    const organizerUser = docSnap.data().name;

    if (titleForm && descriptionForm && descriptionForm && streetForm && cityForm && startDate && endDate) {
      await addDoc(collection(db, 'events'), {
        uid: uidNr,
        title: titleForm,
        description: descriptionForm,
        street: streetForm,
        city: cityForm,
        startDate: new Date(startDate),
        endDate: new Date(endDate),
        photoUrl: photoURL,
        organizer: organizerUser,
      });

      Router.getRouter().navigate('/dashboard');
    } else {
      const error = document.querySelector('form .errorContainer');
      error.innerHTML = '*Fill in everything!';
    }
  },
};

export default Events;
