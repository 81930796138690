import './sass/main.scss';
import App from './App';
import {
  // eslint-disable-next-line max-len
  LoginComponent, ForgotPasswordComponent, RegisterComponent, RegisterdetailComponent, DashboardComponent, InvitedComponent, EventComponent, MeldetComponent, NewEventComponent, NotificationsComponent, SettingsComponent, ProfileComponent,
} from './Components';

const initApp = () => {
  const appContainer = document.getElementById('appContainer');

  const app = new App(appContainer);

  app.addComponent(new LoginComponent());
  app.addComponent(new ForgotPasswordComponent());
  app.addComponent(new RegisterComponent());
  app.addComponent(new RegisterdetailComponent());
  app.addComponent(new DashboardComponent());
  app.addComponent(new InvitedComponent());
  app.addComponent(new EventComponent());
  app.addComponent(new MeldetComponent());
  app.addComponent(new NewEventComponent());
  app.addComponent(new NotificationsComponent());
  app.addComponent(new SettingsComponent());
  app.addComponent(new ProfileComponent());

  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.register('./sw.js');
  }
};

window.addEventListener('load', initApp);
