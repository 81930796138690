// My Firebase Config

// Import the functions you need from the SDKs you need
import * as firebase from 'firebase/app';
import 'regenerator-runtime/runtime';

const firebaseConfig = {
  apiKey: 'AIzaSyBkrWmn_JeORPATrmOAku781p09ilgYIdc',
  authDomain: 'samenuitsamenthuis-e0fc2.firebaseapp.com',
  projectId: 'samenuitsamenthuis-e0fc2',
  storageBucket: 'samenuitsamenthuis-e0fc2.appspot.com',
  messagingSenderId: '849224960395',
  appId: '1:849224960395:web:56427c55a9ad0bdf1f878e',
};

// Initialize Firebase
const initFirebase = () => {
  firebase.initializeApp(firebaseConfig);
};

export default initFirebase;
