// User

import { getAuth, deleteUser } from 'firebase/auth';
import {
  getFirestore, doc, updateDoc, getDoc, deleteDoc,
} from 'firebase/firestore';
import Router from '../router';

const User = {
  //    add user info to the user
  updateUser: async (formName, photoURL) => {
    const formData = new FormData(document.querySelector(formName));
    const fname = formData.get('fname');
    const lname = formData.get('lname');
    const uname = formData.get('uname');
    const number = formData.get('number');
    const name = `${fname} ${lname}`;
    const uid = localStorage.getItem('uid');

    const db = getFirestore();
    const user = doc(db, 'users', uid);

    if (fname && lname && uname && number) {
      await updateDoc(user, {
        photoUrl: photoURL,
        name: name,
        uname: uname,
        number: number,
      });
      Router.getRouter().navigate('/dashboard');
    } else {
      const error = document.querySelector('form .errorContainer');
      error.innerHTML = '*Fill in everything!';
    }
  },

  //    load user data
  loadUser: async () => {
    const db = getFirestore();
    const uid = localStorage.getItem('uid');
    const collection = doc(db, 'users', uid);
    const user = await getDoc(collection);
    return user.data();
  },

  // delete user
  deleteUser: () => {
    const r = confirm('Are you sure you want to delete your account?');
    if (r === true) {
      const auth = getAuth();
      const user = auth.currentUser;
      const db = getFirestore();
      const uid = localStorage.getItem('uid');

      deleteUser(user).then(async () => {
        await deleteDoc(doc(db, 'users', uid));
        Router.getRouter().navigate('/');
      }).catch((error) => {
        console.log(error.message);
      });
    }
  },
};

export default User;
