// Authentication: login, register, ...

import {
  getAuth, signInWithEmailAndPassword, signInWithPopup, FacebookAuthProvider, TwitterAuthProvider, GoogleAuthProvider, createUserWithEmailAndPassword, signOut, onAuthStateChanged, sendPasswordResetEmail,
} from 'firebase/auth';
import {
  getFirestore, doc, setDoc, getDoc,
} from 'firebase/firestore';
import Router from '../router';

const Auth = {
  //    log in with email & password
  login: () => {
    const formData = new FormData(document.querySelector('.authentication'));
    const email = formData.get('email');
    const password = formData.get('password');

    const auth = getAuth();
    signInWithEmailAndPassword(auth, email, password)
      .then(() => {
        Router.getRouter().navigate('/dashboard');
      })
      .catch((error) => {
        const errorMessage = error.message;
        const errorContainer = document.querySelector('form .errorContainer');
        errorContainer.innerHTML = errorMessage;
      });
  },

  //   login with Facebook
  loginWithFacebook: () => {
    const auth = getAuth();
    const provider = new FacebookAuthProvider();
    signInWithPopup(auth, provider)
      .then(async (result) => {
        const { user } = result;
        const db = getFirestore();
        const docs = doc(db, 'users', user.uid);
        const getDocs = await getDoc(docs);
        if (getDocs.exists()) {
          Router.getRouter().navigate('/dashboard');
        } else {
          await setDoc(doc(db, 'users', user.uid), {
            email: user.email,
            photoUrl: user.photoURL,
            name: user.displayName,
            uname: `${(user.displayName).split(' ')[0]}`,
          });
          Router.getRouter().navigate('/dashboard');
        }
      })
      .catch((error) => {
        const errorMessage = error.message;
        let errorContainer = document.querySelector('form .errorContainer');
        errorContainer.innerHTML = errorMessage;
        errorContainer = errorContainer.classList.remove('hide');
      });
  },

  //   login with Twitter
  loginWithTwitter: () => {
    const auth = getAuth();
    const provider = new TwitterAuthProvider();
    signInWithPopup(auth, provider)
      .then(async (result) => {
        const { user } = result;
        const db = getFirestore();
        const docs = doc(db, 'users', user.uid);
        const getDocs = await getDoc(docs);
        if (getDocs.exists()) {
          Router.getRouter().navigate('/dashboard');
        } else {
          await setDoc(doc(db, 'users', user.uid), {
            email: user.email,
            photoUrl: user.photoURL,
            name: user.displayName,
            uname: `${(user.displayName).split(' ')[0]}`,
          });
          Router.getRouter().navigate('/dashboard');
        }
      }).catch((error) => {
        const errorMessage = error.message;
        const errorContainer = document.querySelector('form .errorContainer');
        errorContainer.innerHTML = errorMessage;
      });
  },

  //   login with Google
  loginWithGoogle: () => {
    const auth = getAuth();
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider)
      .then(async (result) => {
        const { user } = result;
        const db = getFirestore();
        const docs = doc(db, 'users', user.uid);
        const getDocs = await getDoc(docs);
        if (getDocs.exists()) {
          Router.getRouter().navigate('/dashboard');
        } else {
          await setDoc(doc(db, 'users', user.uid), {
            email: user.email,
            photoUrl: user.photoURL,
            name: user.displayName,
            uname: `${(user.displayName).split(' ')[0]}`,
          });
          Router.getRouter().navigate('/dashboard');
        }
      }).catch((error) => {
        const errorMessage = error.message;
        const errorContainer = document.querySelector('form .errorContainer');
        errorContainer.innerHTML = errorMessage;
      });
  },

  //   register with email & password
  register: () => {
    const formData = new FormData(document.querySelector('.authentication'));
    const email = formData.get('email');
    const password = formData.get('password');

    const auth = getAuth();
    createUserWithEmailAndPassword(auth, email, password)
      .then(async (userCredential) => {
        const { user } = userCredential;
        const db = getFirestore();
        await setDoc(doc(db, 'users', user.uid), {
          email: user.email,
          photoUrl: '',
          name: '',
          uname: '',
          number: '',
        });
        localStorage.setItem('uid', user.uid);
        Router.getRouter().navigate('/registerdetail');
      })
      .catch((error) => {
        const errorMessage = error.message;
        const errorContainer = document.querySelector('form .errorContainer');
        errorContainer.innerHTML = errorMessage;
      });
  },

  // log out
  logout: () => {
    const r = confirm('Are you sure you want to log out?');
    if (r === true) {
      const auth = getAuth();
      signOut(auth).then(() => {
        Router.getRouter().navigate('/');
      }).catch((error) => {
        const errorMessage = error.message;
        console.log(errorMessage);
      });
    }
  },

  //    logged in
  loggedIn: (path) => {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (user) {
        localStorage.setItem('uid', user.uid);
        Router.getRouter().navigate(path);
      }
    });
  },

  //    logged out
  loggedOut: (path) => {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (!user) {
        Router.getRouter().navigate(path);
      }
    });
  },

  // forgot password
  forgot() {
    const formData = new FormData(document.querySelector('.authentication'));
    const email = formData.get('email');

    const auth = getAuth();
    sendPasswordResetEmail(auth, email)
      .then(() => {
        Router.getRouter().navigate('/');
      })
      .catch((error) => {
        const errorMessage = error.message;
        const errorContainer = document.querySelector('form .errorContainer');
        errorContainer.innerHTML = errorMessage;
      });
  },
};

export default Auth;
